import $ from 'jquery';
import 'slick-carousel';

function isDarkBackgroundx(element) {
    // Get the computed background color of the element
    var backgroundColor = window.getComputedStyle(element).getPropertyValue('background-color');
    
    // Convert the color to an RGB array
    var rgb = backgroundColor.match(/\d+/g);
    
    // Calculate the brightness of the background color
    var brightness = (parseInt(rgb[0]) * 299 + parseInt(rgb[1]) * 587 + parseInt(rgb[2]) * 114) / 1000;
    
    // Return true if the brightness is below a certain threshold, indicating a dark background
    return brightness < 128;
}

// Function to check if an element has a dark background
function isDarkBackground($element) {
    // Add your logic to determine if the element has a dark background
    // For example, you can use the method provided earlier to calculate brightness
    // and determine if it's below a certain threshold
    // Replace this with your actual implementation
    return true; // Placeholder
}

// Function to check if the scroll position of .checkBackground elements has crossed over the header
function checkBackgroundScrollPosition() {
    var headerHeight = $('header').outerHeight();

    $('.checkBackground').each(function() {
        var $element = $(this);
        var elementTop = $element.offset().top;
        var scrollTop = $(window).scrollTop();

        // Check if the scroll position of the element has crossed over the header
        if (elementTop < headerHeight + scrollTop) {
            if ($element.hasClass('backgroundDark')) {
                //console.log('backgroundDark');
                $('header .logo svg').removeClass('text-black');
                $('header .logo svg').addClass('text-white');

                $('header nav').removeClass('text-black');
                $('header nav').addClass('text-white');

                $('header .headerButton').removeClass('button-black');
                $('header .headerButton').addClass('button-white');

            } else if ($element.hasClass('backgroundLight')) {
                //console.log('backgroundLight');
                $('header .logo svg').removeClass('text-white');
                $('header .logo svg').addClass('text-black');

                $('header nav').removeClass('text-white');
                $('header nav').addClass('text-black');

                $('header .headerButton').removeClass('button-white');
                $('header .headerButton').addClass('button-black');

            }
        }
    });
}


jQuery(document).ready(function($) {
    $('.action-button').on('click', function() {
        var action = $(this).data('action');
        
        switch(action) {
            case 'scrollToAnchor':
                // Scroll to anchor
                var anchor = $(this).data('anchor');
                console.log('Scrolling to anchor: ' + anchor);
                if (anchor) {
                    $('html, body').animate({
                        scrollTop: $('.' + anchor).offset().top
                    }, 1000);
                }
                break;
            default:
                console.log('Unknown action');
        }
    });

    // Initialize Slick Carousel for each .testimonial-carousel block
    $('.testimonial-carousel').each(function(index, element) {
        var $carousel = $(element);
        
        // Initialize Slick Carousel for this specific block
        $carousel.slick({
            // Slick Carousel options
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 0,
            // Disable default arrows
            arrows: false,
            // Add more options as needed
        });

        // Bind click event to the next button within this block
        $carousel.parent().find('.nextTestimonial').on('click', function() {
            $carousel.slick('slickNext');
        });

        // Bind click event to the previous button within this block
        $carousel.parent().find('.prevTestimonial').on('click', function() {
            $carousel.slick('slickPrev');
        });
    });

    // Initialize Slick Carousel for each .testimonial-logo block
    $('.testimonial-logos').each(function(index, element) {
        var $carousel = $(element);
        
        // Initialize Slick Carousel for this specific block
        $carousel.slick({
            // Slick Carousel options
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            rows: 0,
            // Disable default arrows
            arrows: false,
            // Autoplay options
            autoplay: true, // Enable autoplay
            autoplaySpeed: 3000, // Autoplay speed in milliseconds (e.g., 3000ms = 3 seconds)
            // Add more options as needed
        });

    });

    var lastScrollTop = 0;

    $(window).scroll(function(){
        var currentScrollTop = $(this).scrollTop();
        
        // Add 'start-scroll' class when scrolled down at least 100px
        if (currentScrollTop >= 100) {
            $('header').addClass('start-scroll');
        } else {
            $('header').removeClass('start-scroll');
        }
        
        // Add 'fixed-header' class when scrolled down at least 375px
        if (currentScrollTop >= 375) {
            $('header').addClass('fixed-header');
        } else {
            $('header').removeClass('fixed-header');
        }
        
        // Determine scrolling direction
        var scrollingDirection = currentScrollTop > lastScrollTop ? 'scrollingDown' : 'scrollingUp';
        
        // Add or remove classes based on scrolling direction
        if (scrollingDirection === 'scrollingDown') {
            $('header').addClass('scrollingDown');
            $('header').removeClass('scrollingUp');
        } else {
            $('header').addClass('scrollingUp');
            $('header').removeClass('scrollingDown');
        }
        
        // Update last scroll position
        lastScrollTop = currentScrollTop;

        checkBackgroundScrollPosition();
    });

});
